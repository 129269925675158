import React, {useState} from 'react';
import './App.css';
import {btMija} from "./mija";
import {FaTemperatureLow, FaBatteryThreeQuarters} from "react-icons/fa";
import {RiSignalTowerLine} from "react-icons/ri";
import {GiWaterDrop} from "react-icons/gi";

const SIMPSONS = ['Homer J. Simpson',
    'Bart Simpson',
    'Marge Simpson',
    'Lisa Simpson',
    'Maggie Simpson',
    'Moe Szyslak',
    'Ned Flanders',
    'Charles Montgomery Burns',
    "Abraham 'Grampa' Simpson",
    'Milhouse Van Houten',
    'Principal Seymour Skinner',
    'Lenny Leonard',
    'Carl Carlson',
    'Waylon Smithers',
    'Chief Clancy Wiggum',
    'Apu Nahasapeemapetilon',
    'Nelson Muntz',
    'Krusty the Clown',
    'Barney Gumble',
    'Ralph Wiggum',
    'Todd Flanders',
    'Edna Krabappel',
    'Selma Bouvier',
    'Patty Bouvier',
    'Rod Flanders',
    'Sideshow Bob',
    'Maude Flanders',
    'Sideshow Mel',
    'Rev. Timothy Lovejoy',
    'Groundskeeper Willie',
    'Dr. Julius Hibbert',
    "Santa's Little Helper",
    'Officer Lou',
    'Martin Prince',
    'CHARACTER',
    'Jimbo Jones',
    'Superintendent Chalmers',
    'Comic Book Guy',
    'Snowball II',
    'Kent Brockman',
    'Elizabeth Hoover',
    'Troy McClure',
    "Mayor 'Diamond Joe' Quimby",
    'Dr. Nick Riviera',
    'Otto Mann',
    'Fat Tony',
    'Sherri & Terri',
    'Helen Lovejoy',
    'Itchy the Mouse',
    'Agnes Skinner',
    'Scratchy the Cat',
    'Kirk Van Houten',
    'Hans Moleman',
    'Kearney Zzyzwicz',
    'Officer Eddie',
    'Duffman',
    'Snake (Jailbird)',
    'Lionel Hutz',
    'Dolph Starbeam',
    'Jasper Beardley',
    'Bumblebee Man',
    'Cletus Spuckler',
    'Prof. John Frink',
    'Luann Van Houten',
    'Manjula Nahasapeemapetilon',
    'Lunchlady Doris',
    'Gil Gunderson',
    'Kang',
    'Sea Captain',
    'CHARACTER',
    'Kodos',
    'Disco Stu',
    'Rainier Wolfcastle/McBain',
    'Wendell Borton',
    'Legs',
    'Brandine Spuckler',
    'Janey Powell',
    'Arnie Pie',
    'Üter',
    'Crazy Cat Lady',
    'Luigi',
    'Squeaky-Voiced Teen',
    'Blue-Haired Lawyer',
    'Louie',
    'Lewis',
    'Cookie Kwan',
    'Dr. Marvin Monroe',
    'Bernice Hibbert',
    'Dewey Largo',
    'Mr. Teeny',
    'Rich Texan',
    'Old Jewish Man',
    'God',
    'Database',
    'Lindsey Naegle',
    'Drederick Tatum',
    'Baby Gerald',
    'Judge Roy Snyder',
    'Mrs. Muntz',
    'Bill',
    'Wiseguy',
    'Marty',
    'Julio']

const Tile = props => {
    const pStyle = {
        display: 'flex',
        justifyContent: 'space-between'
    }
    const cachedName = localStorage.getItem(props.mac) ?? (localStorage.setItem(props.mac, SIMPSONS[Math.floor(Math.random() * SIMPSONS.length)]) || localStorage.getItem(props.mac));

    return <div
        className={'tiles'}
        style={{width: '6rem', border: '1px solid #77889987', margin: '0.5rem', padding: '0.5rem 2rem'}}
        onClick={() => {
            const name = prompt(`Name Sensor ${props.mac}`, cachedName)
            localStorage.setItem(props.mac, name);
        }}>
        <div style={{marginBottom: '0.5rem'}}>{cachedName}</div>
        <div style={pStyle}><FaTemperatureLow/>{' '} <b>{props.temperature ?? '?'}°C</b></div>
        <div style={pStyle}><GiWaterDrop/> {props.humidity ?? '?'}%</div>
        <div style={pStyle}><FaBatteryThreeQuarters/> {props.battery ?? '?'}%</div>
        <div style={pStyle}><RiSignalTowerLine/> {props.rssi ?? '?'}</div>
    </div>
}

function App() {
    const [mijaResponses, setMijaResponses] = useState({})
    const [errorMessage, setErrorMessage] = useState("Click on Screen")

    return (
        <div className="App" onClick={() => {
            btMija((response => {
                const mac = response.mac
                setMijaResponses(prevState => {
                    return {
                        ...prevState,
                        [mac]: response
                    }
                })
            }), (errorMessage) => {
                setErrorMessage(errorMessage)
            }).then(e => {
                console.log(e)
            })
        }}>
            <header className="App-header">
                <p>{errorMessage}</p>


                <p>
                    <div style={{
                        flexWrap: 'wrap', display: 'flex'
                    }}>
                        {Object.values(mijaResponses).sort((a, b) => {
                            return b.rssi - a.rssi
                        }).map(response => {
                            return (<Tile {...response} />)
                        })}
                    </div>
                </p>
            </header>
        </div>
    );
}

export default App;
