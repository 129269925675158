export const btMija = async (measurementCallback, errorCallback) => {

  if(navigator.bluetooth === undefined) {
    errorCallback && errorCallback("Bluetooth API not available");
  }

  const options = { 'filters': [{ 'name': 'MJ_HT_V1' }] };
   let scan
  try {
   scan = await navigator.bluetooth.requestLEScan(options);
  } catch (e) {
    errorCallback && errorCallback(e.message);
    return;
  }

  errorCallback && errorCallback("Scan...");

  const resultsAfter60sec = {};
  setTimeout(() => {
    scan.stop();
    errorCallback && errorCallback("Bluetooth-LE scan stopped.");
    for (let [key, value] of Object.entries(resultsAfter60sec)) {
      console.log(`ID: ${key}`);
      console.table(value);
    }
  }, 60 * 1000);


  navigator.bluetooth.addEventListener('advertisementreceived', event => {
    const deviceID = event.device.id;
    let measurement = resultsAfter60sec[deviceID] ?? {};
    measurement = {
      ...measurement,
      rssi: event.rssi
    };
    event.serviceData.forEach((valueDataView, key) => {
      const rawByteData = [...new Uint8Array(valueDataView.buffer)].map(b => {
        return b.toString(16).padStart(2, '0');
      });
      if (!rawByteData.join(' ').startsWith('50 20 aa 01')) {
        return;
      }
      const mac = rawByteData.slice(5, 10).join(':');

      measurement = {
        ...measurement,
        mac: mac
      };

      let dataType = rawByteData[11];
      if (dataType === '0d') {
        measurement = {
          ...measurement,
          temperature: parseInt(`${rawByteData[15]}${rawByteData[14]}`, 16) / 10.0,
          humidity: parseInt(`${rawByteData[17]}${rawByteData[16]}`, 16) / 10.0
        };
      }

      if (dataType === '0a') {
        measurement = {
          ...measurement,
          battery: parseInt(rawByteData[14], 16)
        };
      }

      if (dataType === '06') {
        measurement = {
          ...measurement,
          humidity: parseInt(`${rawByteData[15]}${rawByteData[14]}`, 16) / 10.0
        };
      }

      if (dataType === '04') {
        const temp = parseInt(`${rawByteData[15]}${rawByteData[14]}`, 16) / 10.0;

        measurement = {
          ...measurement,
          temperature: temp
        };
      }
      resultsAfter60sec[deviceID] = measurement;
      measurementCallback(measurement);
    });
  });
};